import {
  SET_CURRENT_PAGE_DEVICE,
  SET_SELECTED_PAGE_SIZE_DEVICE,
  SET_SELECTED_ORDER_OPTION_DEVICE,
  SET_SEARCH_DEVICE,
  SET_HAS_NEXT_DEVICE,
  OPEN_SUB_MENU,
} from '../actions';

const INIT_STATE = {
  currentPage: 1,
  selectedPageSize: 20,
  selectedOrderOption: {
    column: 'createDate,DESC',
    label: 'Create Date | DESC',
  },
  hasNext: false,
  search: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_DEVICE:
      return { ...state, currentPage: action.payload };
    case SET_SELECTED_PAGE_SIZE_DEVICE:
      return { ...state, selectedPageSize: action.payload, currentPage: 1 };
    case SET_SELECTED_ORDER_OPTION_DEVICE:
      return { ...state, selectedOrderOption: action.payload, currentPage: 1 };
    case SET_SEARCH_DEVICE:
      return { ...state, search: action.payload, currentPage: 1 };
    case SET_HAS_NEXT_DEVICE:
      return { ...state, hasNext: action.payload };
    case OPEN_SUB_MENU:
      return INIT_STATE;
    default:
      return { ...state };
  }
};
